/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import {
	appPages,
	authPages,
	componentsPages,
	examplePages,
	listETicketsOnlinePage,
	listPoliPage,
	puskesmasOnlinePages,
} from '../config/pages.config';
import NotFoundPage from '../pages/NotFound.page';
import LoginPage from '../pages/Login.page';
import SalesDashboardPage from '../pages/Dashboard/SalesDashboard.page';

//Gudang Vaksin
import StokBarang from '../pages/GudangVaksin/StokBarang/stokbarang';
import PenerimaanBarang from '../pages/GudangVaksin/PenerimaanBarang/penerimaanBarang';
import PengeluaranBarangVaksin from '../pages/GudangVaksin/PengeluaranBarang/PengeluaranBarangVaksin';
import KartuStokVaksin from '../pages/GudangVaksin/KartuStok/KartuStok';
import StokOpnameVaksin from '../pages/GudangVaksin/StokOpname/StokOpnameVaksin';
import LapKetersediaan from '../pages/GudangVaksin/LapKetersediaan/LapKetersediaan';
import LapDistribusi from '../pages/GudangVaksin/LapDistribusi/LapDistribusi';
import LapDistribusiUnit from '../pages/GudangVaksin/LapDistribusiUnit/LapDistribusiUnit';
import LapSoTriwulan from '../pages/GudangVaksin/LapSoTriwulan/LapSoTriwulan';
// Gudang Besar
import StokBarangBesar from '../pages/GudangBesar/StokBarang/StokBarangBesar';
import PenerimaanBarangBesar from '../pages/GudangBesar/PenerimaanBarang/PenerimaanBarangBesar';
import PengeluaranBarangBesar from '../pages/GudangBesar/PengeluaranBarang/PengeluaranBarangBesar';
import ReturPkmBesar from '../pages/GudangBesar/ReturPkm/ReturPkmBesar';
import StokOpnameBesar from '../pages/GudangBesar/StokOpname/StokOpnameBesar';

const ProfilePage = lazy(() => import('../pages/Profile.page'));

const UnderConstructionPage = lazy(() => import('../pages/UnderConstruction.page'));

const contentRoutes: RouteProps[] = [
	// auth
	{ path: authPages.loginPage.to, element: <LoginPage /> },
	{ path: authPages.profilePage.to, element: <ProfilePage /> },
	//

	// Dashboard
	{
		path: appPages.dashboardPage.subPages.utamaPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.dashboardPage.subPages.lplpoPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.dashboardPage.subPages.rkoPage.to,
		element: <NotFoundPage />,
	},
	// ketersediaan
	{
		path: appPages.ketersediaanPage.subPages.obatPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.ketersediaanPage.subPages.vaksinPage.to,
		element: <NotFoundPage />,
	},
	// Berita Update
	{
		path: appPages.updateBeritaPage.to,
		element: <NotFoundPage />,
	},
	// E-Logistik kemkes
	{
		path: appPages.eLogistikKemkesPage.subPages.indikatorPage.subPages.kirimIndikatorPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.eLogistikKemkesPage.subPages.indikatorPage.subPages.lihatIndikatorPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.eLogistikKemkesPage.subPages.ketersediaanKemkesPage.subPages.obatKemkesPage
			.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.eLogistikKemkesPage.subPages.ketersediaanKemkesPage.subPages.vaksinKemkesPage
			.to,
		element: <NotFoundPage />,
	},
	// Info Ketersediaan
	{
		path: appPages.infoKetersediaanPage.to,
		element: <NotFoundPage />,
	},
	// Gudang Besar
	{
		path: appPages.gudangBesarPage.subPages.stokBarangPage.to,
		element: <StokBarangBesar />,
	},
	{
		path: appPages.gudangBesarPage.subPages.penerimaanBarangPage.to,
		element: <PenerimaanBarangBesar />,
	},
	{
		path: appPages.gudangBesarPage.subPages.pengeluaranBarangPage.to,
		element: <PengeluaranBarangBesar />,
	},
	{
		path: appPages.gudangBesarPage.subPages.returPkmPage.to,
		element: <ReturPkmBesar />,
	},
	{
		path: appPages.gudangBesarPage.subPages.stokOpnamePage.to,
		element: <StokOpnameBesar />,
	},
	// Gudang Vaksin
	{
		path: appPages.gudangVaksinPage.subPages.stokBarangVaksinPage.to,
		element: <StokBarang />,
	},
	{
		path: appPages.gudangVaksinPage.subPages.penerimaanBarangVaksinPage.to,
		element: <PenerimaanBarang />,
	},
	{
		path: appPages.gudangVaksinPage.subPages.pengeluaranBarangVaksinPage.to,
		element: <PengeluaranBarangVaksin />,
	},
	{
		path: appPages.gudangVaksinPage.subPages.katuStokVaksinPage.to,
		element: <KartuStokVaksin />,
	},
	{
		path: appPages.gudangVaksinPage.subPages.stokOpnameVaksinPage.to,
		element: <StokOpnameVaksin />,
	},
	{
		path: appPages.gudangVaksinPage.subPages.lapKetersediaanPage.to,
		element: <LapKetersediaan />,
	},
	{
		path: appPages.gudangVaksinPage.subPages.lapDistribusiPage.to,
		element: <LapDistribusi />,
	},
	{
		path: appPages.gudangVaksinPage.subPages.lapDistribusiUnitPage.to,
		element: <LapDistribusiUnit />,
	},
	{
		path: appPages.gudangVaksinPage.subPages.lapSoPage.to,
		element: <LapSoTriwulan />,
	},
	// Gudang Karantina
	{
		path: appPages.gudangKarantinaPage.subPages.dataKarantinaPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.gudangKarantinaPage.subPages.dataPemusnahanPage.to,
		element: <NotFoundPage />,
	},
	// Laporan Gudang
	{
		path: appPages.lapGudangPage.subPages.kartuStokbBatchPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.lapGudangPage.subPages.kartuStokbGroupPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.lapGudangPage.subPages.ketersediaanGudangPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.lapGudangPage.subPages.mutasiSediaanPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.lapGudangPage.subPages.distribusiHarianPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.lapGudangPage.subPages.distribusiUnitDetailPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.lapGudangPage.subPages.distribusiKeunganPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.lapGudangPage.subPages.psikotropikaPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.lapGudangPage.subPages.rkoPuskesmasPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.lapGudangPage.subPages.lplpoPuskesmasPage.to,
		element: <NotFoundPage />,
	},
	// Master
	{
		path: appPages.masterPage.subPages.dataFornasPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.masterPage.subPages.dataJknPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.masterPage.subPages.dataLplpoPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.masterPage.subPages.dataObatIndikatorPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.masterPage.subPages.dataProgramPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.masterPage.subPages.dataVaksinPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.masterPage.subPages.dataPegawaiPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.masterPage.subPages.dataPuskesmasPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.masterPage.subPages.dataPemberiPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.masterPage.subPages.dataPenerimaPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.masterPage.subPages.dataSupplierPage.to,
		element: <NotFoundPage />,
	},
	{
		path: appPages.masterPage.subPages.dataRumahSakitPage.to,
		element: <NotFoundPage />,
	},

	{ path: '*', element: <NotFoundPage /> },
];

export default contentRoutes;
