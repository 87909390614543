import React from 'react';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Container from '../../../components/layouts/Container/Container';
import Subheader, { SubheaderLeft } from '../../../components/layouts/Subheader/Subheader';
import Card, { CardHeader, CardBody, CardFooter } from '../../../components/ui/Card';
import GTable from '../../../components/ui/GTable';
import Input from '../../../components/form/Input';
import SelectReact from '../../../components/form/SelectReact';
import Tooltip from '../../../components/ui/Tooltip';
import Button from '../../../components/ui/Button';
import useTableOpnameBesar from './hooks/useTableOpnameBesar';

function StokOpnameBesar() {
	const { columns } = useTableOpnameBesar();

	const staticData = [
		{
			bulan: 'Januari',
			tahun: 2023,
			keterangan: 'Peningkatan stok untuk awal tahun',
		},
		{
			bulan: 'Februari',
			tahun: 2023,
			keterangan: 'Pengeluaran besar untuk kebutuhan rumah sakit',
		},
		{
			bulan: 'Maret',
			tahun: 2023,
			keterangan: 'Stok stabil, sedikit pengeluaran',
		},
		{
			bulan: 'April',
			tahun: 2023,
			keterangan: 'Penerimaan barang baru dari supplier utama',
		},
		{
			bulan: 'Mei',
			tahun: 2023,
			keterangan: 'Penyesuaian stok dan inventaris',
		},
	];

	const ListKonseling = [
		{ label: 'Tersedia', value: 'tersedia' },
		{ label: 'Kosong', value: 'kosong' },
		{ label: 'Segera Ada', value: 'segera ada' },
	];
	return (
		<PageWrapper>
			<Subheader>
				<SubheaderLeft>
					<h3 className='font-bold text-blue-600'>
						STOK OPNAME <span className='text-lg'>(Gudang Besar)</span>
					</h3>
				</SubheaderLeft>
			</Subheader>
			<Container>
				<Card>
					<CardBody>
						<div className='grid grid-cols-12 items-center gap-4'>
							<Input
								type='text'
								dimension='lg'
								id='name'
								name='name'
								placeholder='Ketik Nama/Kode Barang/Batch/Program'
								className='col-span-6 w-full'
							/>
							<div className='col-span-4 flex justify-start gap-2'>
								<Tooltip text='Cari' placement='top'>
									<Button
										type='button'
										icon='HeroMagnifyingGlass'
										size='lg'
										variant='solid'
										color='emerald'
									/>
								</Tooltip>

								<Tooltip text='Reset' placement='top'>
									<Button
										type='button'
										icon='HeroArrowPath'
										size='lg'
										variant='solid'
										color='blue'
									/>
								</Tooltip>

								<Tooltip text='Export Excel' placement='top'>
									<Button type='button' size='lg' variant='solid' color='lime'>
										Buat Faktur
									</Button>
								</Tooltip>
							</div>
						</div>
					</CardBody>
				</Card>
				<Card className='mt-4'>
					<CardBody>
						<GTable data={staticData} columns={columns} />
					</CardBody>
				</Card>
			</Container>
		</PageWrapper>
	);
}

export default StokOpnameBesar;
