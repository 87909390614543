import React from 'react';
import Aside, { AsideBody, AsideFooter, AsideHead } from '../../../components/layouts/Aside/Aside';
import LogoAndAsideTogglePart from './_parts/LogoAndAsideToggle.part';
import DarkModeSwitcherPart from './_parts/DarkModeSwitcher.part';
import { appPages, componentsPages } from '../../../config/pages.config';
import Nav, { NavCollapse, NavItem, NavTitle } from '../../../components/layouts/Navigation/Nav';

import UserTemplate from '../User/User.template';

const DefaultAsideTemplate = () => {
	return (
		<Aside>
			<AsideHead>
				<LogoAndAsideTogglePart />
			</AsideHead>
			<AsideBody>
				<Nav>
					<NavTitle>Menu</NavTitle>

					<NavCollapse
						text={appPages.dashboardPage.text}
						to={appPages.dashboardPage.to}
						icon={appPages.dashboardPage.icon}>
						<NavItem {...appPages.dashboardPage.subPages.utamaPage}></NavItem>
						<NavItem {...appPages.dashboardPage.subPages.lplpoPage}></NavItem>
						<NavItem {...appPages.dashboardPage.subPages.rkoPage}></NavItem>
					</NavCollapse>

					<NavCollapse
						text={appPages.ketersediaanPage.text}
						to={appPages.ketersediaanPage.to}
						icon={appPages.ketersediaanPage.icon}>
						<NavItem {...appPages.ketersediaanPage.subPages.obatPage}></NavItem>
						<NavItem {...appPages.ketersediaanPage.subPages.vaksinPage}></NavItem>
					</NavCollapse>

					<NavItem
						text={appPages.updateBeritaPage.text}
						to={appPages.updateBeritaPage.to}
						icon={appPages.updateBeritaPage.icon}
					/>
					<NavCollapse
						text={appPages.eLogistikKemkesPage.text}
						to={appPages.eLogistikKemkesPage.to}
						icon={appPages.eLogistikKemkesPage.icon}>
						<NavCollapse
							text={appPages.eLogistikKemkesPage.subPages.indikatorPage.text}
							to={appPages.eLogistikKemkesPage.subPages.indikatorPage.to}
							icon={appPages.eLogistikKemkesPage.subPages.indikatorPage.icon}>
							<NavItem
								{...appPages.eLogistikKemkesPage.subPages.indikatorPage.subPages
									.kirimIndikatorPage}></NavItem>
							<NavItem
								{...appPages.eLogistikKemkesPage.subPages.indikatorPage.subPages
									.lihatIndikatorPage}></NavItem>
						</NavCollapse>
						<NavCollapse
							text={appPages.eLogistikKemkesPage.subPages.ketersediaanKemkesPage.text}
							to={appPages.eLogistikKemkesPage.subPages.ketersediaanKemkesPage.to}
							icon={
								appPages.eLogistikKemkesPage.subPages.ketersediaanKemkesPage.icon
							}>
							<NavItem
								{...appPages.eLogistikKemkesPage.subPages.ketersediaanKemkesPage
									.subPages.obatKemkesPage}></NavItem>
							<NavItem
								{...appPages.eLogistikKemkesPage.subPages.ketersediaanKemkesPage
									.subPages.vaksinKemkesPage}></NavItem>
						</NavCollapse>
					</NavCollapse>

					<NavItem
						text={appPages.infoKetersediaanPage.text}
						to={appPages.infoKetersediaanPage.to}
						icon={appPages.infoKetersediaanPage.icon}
					/>

					<NavCollapse
						text={appPages.gudangBesarPage.text}
						to={appPages.gudangBesarPage.to}
						icon={appPages.gudangBesarPage.icon}>
						<NavItem {...appPages.gudangBesarPage.subPages.stokBarangPage}></NavItem>
						<NavItem
							{...appPages.gudangBesarPage.subPages.penerimaanBarangPage}></NavItem>
						<NavItem
							{...appPages.gudangBesarPage.subPages.pengeluaranBarangPage}></NavItem>
						<NavItem {...appPages.gudangBesarPage.subPages.returPkmPage}></NavItem>
						<NavItem {...appPages.gudangBesarPage.subPages.stokOpnamePage}></NavItem>
					</NavCollapse>
					<NavCollapse
						text={appPages.gudangVaksinPage.text}
						to={appPages.gudangVaksinPage.to}
						icon={appPages.gudangVaksinPage.icon}>
						<NavItem
							{...appPages.gudangVaksinPage.subPages.stokBarangVaksinPage}></NavItem>
						<NavItem
							{...appPages.gudangVaksinPage.subPages
								.penerimaanBarangVaksinPage}></NavItem>
						<NavItem
							{...appPages.gudangVaksinPage.subPages
								.pengeluaranBarangVaksinPage}></NavItem>
						<NavItem
							{...appPages.gudangVaksinPage.subPages.katuStokVaksinPage}></NavItem>
						<NavItem
							{...appPages.gudangVaksinPage.subPages.stokOpnameVaksinPage}></NavItem>
						<NavItem
							{...appPages.gudangVaksinPage.subPages.lapKetersediaanPage}></NavItem>
						<NavItem
							{...appPages.gudangVaksinPage.subPages.lapDistribusiPage}></NavItem>
						<NavItem
							{...appPages.gudangVaksinPage.subPages.lapDistribusiUnitPage}></NavItem>
						<NavItem {...appPages.gudangVaksinPage.subPages.lapSoPage}></NavItem>
					</NavCollapse>

					<NavCollapse
						text={appPages.gudangKarantinaPage.text}
						to={appPages.gudangKarantinaPage.to}
						icon={appPages.gudangKarantinaPage.icon}>
						<NavItem
							{...appPages.gudangKarantinaPage.subPages.dataKarantinaPage}></NavItem>
						<NavItem
							{...appPages.gudangKarantinaPage.subPages.dataPemusnahanPage}></NavItem>
					</NavCollapse>
					<NavCollapse
						text={appPages.lapGudangPage.text}
						to={appPages.lapGudangPage.to}
						icon={appPages.lapGudangPage.icon}>
						<NavItem {...appPages.lapGudangPage.subPages.kartuStokbBatchPage}></NavItem>
						<NavItem {...appPages.lapGudangPage.subPages.kartuStokbGroupPage}></NavItem>
						<NavItem
							{...appPages.lapGudangPage.subPages.ketersediaanGudangPage}></NavItem>
						<NavItem {...appPages.lapGudangPage.subPages.mutasiSediaanPage}></NavItem>
						<NavItem
							{...appPages.lapGudangPage.subPages.distribusiHarianPage}></NavItem>
						<NavItem
							{...appPages.lapGudangPage.subPages.distribusiUnitDetailPage}></NavItem>
						<NavItem
							{...appPages.lapGudangPage.subPages.distribusiKeunganPage}></NavItem>
						<NavItem {...appPages.lapGudangPage.subPages.psikotropikaPage}></NavItem>
						<NavItem {...appPages.lapGudangPage.subPages.rkoPuskesmasPage}></NavItem>
						<NavItem {...appPages.lapGudangPage.subPages.lplpoPuskesmasPage}></NavItem>
					</NavCollapse>
					<NavCollapse
						text={appPages.masterPage.text}
						to={appPages.masterPage.to}
						icon={appPages.masterPage.icon}>
						<NavItem {...appPages.masterPage.subPages.dataFornasPage}></NavItem>
						<NavItem {...appPages.masterPage.subPages.dataJknPage}></NavItem>
						<NavItem {...appPages.masterPage.subPages.dataLplpoPage}></NavItem>
						<NavItem {...appPages.masterPage.subPages.dataObatIndikatorPage}></NavItem>
						<NavItem {...appPages.masterPage.subPages.dataProgramPage}></NavItem>
						<NavItem {...appPages.masterPage.subPages.dataVaksinPage}></NavItem>
						<NavItem {...appPages.masterPage.subPages.dataPegawaiPage}></NavItem>
						<NavItem {...appPages.masterPage.subPages.dataPuskesmasPage}></NavItem>
						<NavItem {...appPages.masterPage.subPages.dataPemberiPage}></NavItem>
						<NavItem {...appPages.masterPage.subPages.dataPenerimaPage}></NavItem>
						<NavItem {...appPages.masterPage.subPages.dataSupplierPage}></NavItem>
						<NavItem {...appPages.masterPage.subPages.dataRumahSakitPage}></NavItem>
					</NavCollapse>
				</Nav>
			</AsideBody>
			<AsideFooter>
				<UserTemplate />
				<DarkModeSwitcherPart />
			</AsideFooter>
		</Aside>
	);
};

export default DefaultAsideTemplate;
