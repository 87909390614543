/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { createContext, FC, ReactNode, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage';
import { authPages, puskesmasOnlinePages } from '../config/pages.config';
import { TUser } from '../mocks/db/users.db';
import useAuthLogin from '../hooks/useAuthLogin';
import useAxiosFunction from '../hooks/useAxiosFunction';

export interface IAuthContextProps {
	usernameStorage: string | ((newValue: string | null) => void) | null;
	usernameStorageAntrianOnlie: string | ((newValue: string | null) => void) | null;
	tokenStorageAntrianOnline: string | ((newValue: string | null) => void) | null;
	tokenStorage: string | ((newValue: string | null) => void) | null;
	onLogin: (username: string, password: string) => Promise<void>;
	onLoginOnline: (username: string, password: string) => Promise<void>;
	onLogout: () => void;
	onLogoutOnline: () => void;
	userData: TUser;
	isLoading: boolean;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthProviderProps {
	children: ReactNode;
}
export const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
	const [usernameStorage, setUserName] = useLocalStorage('user', null);
	const [tokenStorage, setTokenStorage] = useLocalStorage('token', null);
	const [usernameStorageAntrianOnlie, setUserNameAntrianOnline] = useLocalStorage(
		'userAntrianOnline',
		null,
	);
	const [tokenStorageAntrianOnline, setTokenStorageAntrianOnline] = useLocalStorage(
		'tokenAntrianOnline',
		null,
	);

	const { response, isLoading, getCheckUser } = useAuthLogin(usernameStorage as string);
	const { axiosFetch } = useAxiosFunction();
	const userData = response as TUser;

	const navigate = useNavigate();

	// call this function when you want to authenticate the user
	const onLogin = async (username: string, password: string) => {
		await getCheckUser(username, password).then(async (res: any) => {
			if (typeof setTokenStorage === 'function' && typeof setUserName === 'function') {
				if (res?.token) {
					await setTokenStorage(res.token).then(() => navigate('/'));
				}
				await setUserName(username).then(() => {});
			}
		});
	};

	const onLoginOnline = async (username: string, password: string) => {
		await getCheckUser(username, password, 'antrian').then(async (res: any) => {
			if (
				typeof setTokenStorageAntrianOnline === 'function' &&
				typeof setUserNameAntrianOnline === 'function'
			) {
				if (res?.token) {
					await setTokenStorageAntrianOnline(res.token).then(() =>
						navigate(puskesmasOnlinePages.listCategoryPuskesmasOnline.to),
					);
				}
				await setUserNameAntrianOnline(username).then(() => {});
			}
		});
	};

	const onLogoutOnline = async () => {
		if (
			typeof setUserNameAntrianOnline === 'function' &&
			typeof setTokenStorageAntrianOnline === 'function'
		) {
			await setUserNameAntrianOnline(null);
			await setTokenStorageAntrianOnline(null);
		}
		navigate(`../${puskesmasOnlinePages.onlineLoginPage.to}`, { replace: true });
	};

	// call this function to sign out logged-in user
	const onLogout = async () => {
		if (typeof setUserName === 'function' && typeof setTokenStorage === 'function') {
			await setUserName(null);
			await setTokenStorage(null);
		}
		navigate(`../${authPages.loginPage.to}`, { replace: true });
	};

	const value: IAuthContextProps = useMemo(
		() => ({
			usernameStorageAntrianOnlie,
			tokenStorageAntrianOnline,
			usernameStorage,
			tokenStorage,
			onLogin,
			onLoginOnline,
			onLogout,
			onLogoutOnline,
			userData,
			isLoading,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			usernameStorage,
			userData,
			tokenStorage,
			tokenStorageAntrianOnline,
			usernameStorageAntrianOnlie,
		],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	return useContext(AuthContext);
};
