import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import Tooltip from '../../../../components/ui/Tooltip';
import Button from '../../../../components/ui/Button';

export default function useTableStokOpnameVaksin() {
	const columns: ColumnDef<any>[] = [
		{
			accessorKey: 'no',
			header: 'No',
			size: 20,
			cell: ({ row }: { row: { index: number } }) => (
				<div className='w-[10px]'>{row.index + 1}</div>
			),
		},
		{
			accessorKey: 'bulan',
			header: 'BULAN',
		},
		{
			accessorKey: 'tahun',
			header: 'TAHUN',
		},
		{
			accessorKey: 'keterangan',
			header: 'KETERANGAN',
		},
		{
			accessorKey: 'action',
			header: '#',
			cell: ({ row }: any) => {
				return (
					<div className='flex space-x-2'>
						<Tooltip text='Lihat'>
							<Button
								type='button'
								size='lg'
								icon='heroPencil'
								variant='solid'
								color='emerald'
								className='h-[34px] w-[34px] text-sm text-white transition-colors duration-300'
								onClick={() => {}}
							/>
						</Tooltip>
					</div>
				);
			},
		},
	];

	return { columns };
}
