import React, { useState } from 'react';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Container from '../../../components/layouts/Container/Container';
import Subheader, { SubheaderLeft } from '../../../components/layouts/Subheader/Subheader';
import Card, { CardBody } from '../../../components/ui/Card';
import GTable from '../../../components/ui/GTable';
import Input from '../../../components/form/Input';
import SelectReact from '../../../components/form/SelectReact';
import Tooltip from '../../../components/ui/Tooltip';
import Button from '../../../components/ui/Button';

function LapDistribusi() {
	const yearOptions = Array.from({ length: 2024 - 2017 + 1 }, (_, index) => ({
		value: (2017 + index).toString(),
		label: (2017 + index).toString(),
	}));

	// Mendapatkan tahun saat ini
	const currentYear = new Date().getFullYear().toString();
	const [selectedYear, setSelectedYear] = useState(currentYear);

	const handleYearChange = (selectedOption: any) => {
		setSelectedYear(selectedOption.value);
	};

	return (
		<PageWrapper>
			<Subheader>
				<SubheaderLeft>
					<h3 className='font-bold text-blue-600'>DISTRIBUSI VAKSIN UNIT</h3>
				</SubheaderLeft>
			</Subheader>
			<Container>
				<Card>
					<CardBody>
						<div className='grid grid-cols-12 items-center gap-4'>
							<div className='col-span-2'>
								<SelectReact
									dimension='lg'
									options={yearOptions}
									id='year'
									name='year'
									onChange={handleYearChange}
									value={yearOptions.find((year) => year.value === selectedYear)}
								/>
							</div>
							<Input
								type='text'
								dimension='lg'
								id='name'
								name='name'
								placeholder='Ketikan Nama Barang (Automatic)'
								className='col-span-4 w-full'
							/>
							<div className='col-span-4 flex justify-start gap-2'>
								<Tooltip text='Cari' placement='top'>
									<Button
										type='button'
										icon='HeroMagnifyingGlass'
										size='lg'
										variant='solid'
										color='emerald'
									/>
								</Tooltip>
								<Tooltip text='Reset' placement='top'>
									<Button
										type='button'
										icon='HeroArrowPath'
										size='lg'
										variant='solid'
										color='blue'
									/>
								</Tooltip>
								<Tooltip text='Export Excel' placement='top'>
									<Button
										type='button'
										icon='HeroDocument'
										size='lg'
										variant='solid'
										color='lime'
									/>
								</Tooltip>
								<Tooltip text='Print' placement='top'>
									<Button
										type='button'
										icon='HeroPrinter'
										size='lg'
										variant='solid'
										color='sky'
									/>
								</Tooltip>
							</div>
						</div>
					</CardBody>
				</Card>
			</Container>
		</PageWrapper>
	);
}

export default LapDistribusi;
