/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/function-component-definition */
import React, { useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Table, { THead, Th, ThResizer, Tr } from './Table';

interface IPaginationState {
	page: number;
	size: number;
	total: number;
	query: any;
}

export default function GTableEmpty(props: any) {
	const { data, columns, titleNotFound, subtitle } = props;
	const [state, setState] = useState<IPaginationState>({
		page: 1,
		size: 10,
		total: 0,
		query: {},
	});
	const table = useReactTable({
		data,
		columns,
		pageCount: Math.ceil(data.length / state.size),
		state: {
			pagination: {
				pageIndex: state.page,
				pageSize: state.size,
			},
		},
		onPaginationChange: (updater) => {
			const newState =
				typeof updater === 'function'
					? updater({ pageIndex: state.page, pageSize: state.size })
					: updater;
			setState((prev) => {
				return {
					...prev,
					page: newState.pageIndex,
					size: newState.pageSize,
				};
			});
		},
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div className='relative my-4'>
			<div className=' w-full overflow-x-auto'>
				<Table className='w-full'>
					<THead className='bg-[#e5e7eb]'>
						{table.getHeaderGroups().map((headerGroup: any) => (
							<Tr key={headerGroup.id}>
								{headerGroup.headers.map((header: any) => {
									return (
										<Th
											isResizable
											key={header.id}
											{...{
												colSpan: header.colSpan,
												style: {
													width: header.getSize(),
												},
											}}>
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef.header,
														header.getContext(),
													)}
											<ThResizer
												isResizing={header.column.getIsResizing()}
												{...{
													onMouseDown: header.getResizeHandler(),
													onTouchStart: header.getResizeHandler(),
												}}
											/>
										</Th>
									);
								})}
							</Tr>
						))}
					</THead>
				</Table>
				<div className='flex min-h-[196px] flex-col items-center justify-center'>
					<div className='text-fs-5 text-xl font-bold'>
						{titleNotFound || 'Belum ada data'}
					</div>
					<div className='mt-3 text-[#94A3B8]'>{subtitle}</div>
				</div>
			</div>
		</div>
	);
}
