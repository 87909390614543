/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useEffect, useState } from 'react';
import axios from 'axios';
import usersDb, { TUser } from '../mocks/db/users.db';
// import usersDb, { TUser } from '../db/users.db';

const useAuthLogin = (username: string) => {
	const allUserData = usersDb;
	const userData = allUserData.filter((f) => f.username === username)[0];
	const BASE_URL = process.env.REACT_APP_API_URL;

	const getCheckUser = async (userNameOrMail: string, password: string, type?: string) => {
		setIsLoading(true);
		try {
			const response = await axios.post(
				`${BASE_URL}/api/v1/login`,
				{
					username: userNameOrMail,
					type,
					password,
				},
				{
					headers: {
						'Content-Type': 'application/json',
					},
				},
			);
			setIsLoading(false);
			return response.data; // Assuming the API returns some data you need
		} catch (error: any) {
			if (error?.response) {
				setIsLoading(false);
				if (error?.response.status === 401) {
					return Promise.reject(error);
				}
				if (error?.response.status === 401) {
					throw new Error('The password is invalid!', { cause: 'password' });
				} else if (error.response.status === 404) {
					throw new Error('The username is invalid!', { cause: 'username' });
				} else if (error.response.status === 500) {
					throw new Error('Internal server error');
				}
			} else if (error.request) {
				setIsLoading(false);
				throw new Error('No response received from the server');
			} else {
				setIsLoading(false);
				throw new Error('Error in setting up the request');
			}
		}
	};

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [response, setResponse] = useState<TUser | undefined>();
	useEffect(() => {
		setTimeout(() => {
			setResponse(userData);
			setIsLoading(false);
		}, 500);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [username]);

	return { response, isLoading, getCheckUser, allUserData };
};

export default useAuthLogin;
