import React from 'react';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Container from '../../../components/layouts/Container/Container';
import Subheader, { SubheaderLeft } from '../../../components/layouts/Subheader/Subheader';
import Card, { CardHeader, CardBody, CardFooter } from '../../../components/ui/Card';
import GTable from '../../../components/ui/GTable';
import Input from '../../../components/form/Input';
import SelectReact from '../../../components/form/SelectReact';
import Tooltip from '../../../components/ui/Tooltip';
import Button from '../../../components/ui/Button';
import useTableKartuStokVaksin from './Hooks/useTableKartuStokvaksin';

function KartuStokVaksin() {
	const { columns } = useTableKartuStokVaksin();

	const staticData = [
		{
			tgl_distribusi: '2023-04-05',
			no_faktur: 'FTK001',
			supplier: 'Distribusi',
			unit_penerima: 'Unit A',
			grand_total: 3000000,
			item_barang: 'Paracetamol',
		},
		{
			tgl_distribusi: '2023-04-10',
			no_faktur: 'FTK002',
			supplier: 'Distribusi',
			unit_penerima: 'Unit B',
			grand_total: 1500000,
			item_barang: 'Amoxicillin',
		},
		{
			tgl_distribusi: '2023-04-15',
			no_faktur: 'FTK003',
			supplier: 'Distribusi',
			unit_penerima: 'Unit C',
			grand_total: 4500000,
			item_barang: 'Vitamin C',
		},
	];

	const ListKonseling = [
		{ label: 'Tersedia', value: 'tersedia' },
		{ label: 'Kosong', value: 'kosong' },
		{ label: 'Segera Ada', value: 'segera ada' },
	];
	return (
		<PageWrapper>
			<Subheader>
				<SubheaderLeft>
					<h3 className='font-bold text-blue-600'>
						KARTU STOK <span className='text-lg'>(Gudang Vaksin)</span>
					</h3>
				</SubheaderLeft>
			</Subheader>
			<Container>
				<Card>
					<CardBody>
						<div className='grid grid-cols-12 items-center gap-4'>
							<Input
								type='text'
								dimension='lg'
								id='name'
								name='name'
								placeholder='Ketik Nama/Kode Barang/Barcode'
								className='col-span-7 w-full'
							/>
							<div className='col-span-4 flex justify-start gap-2'>
								<Tooltip text='Cari' placement='top'>
									<Button
										type='button'
										icon='HeroMagnifyingGlass'
										size='lg'
										variant='solid'
										color='emerald'
									/>
								</Tooltip>

								<Tooltip text='Reset' placement='top'>
									<Button
										type='button'
										icon='HeroArrowPath'
										size='lg'
										variant='solid'
										color='blue'
									/>
								</Tooltip>

								<Tooltip text='Export Excel' placement='top'>
									<Button type='button' size='lg' variant='solid' color='lime'>
										Faktur Baru
									</Button>
								</Tooltip>
							</div>
						</div>
					</CardBody>
				</Card>
				{/* <Card className='mt-4'>
					<CardBody>
						<GTable data={staticData} columns={columns} />
					</CardBody>
				</Card> */}
			</Container>
		</PageWrapper>
	);
}

export default KartuStokVaksin;
