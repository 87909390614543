import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import Tooltip from '../../../../components/ui/Tooltip';
import Button from '../../../../components/ui/Button';

export default function useTablePengeluaranBesar() {
	const columns: ColumnDef<any>[] = [
		{
			accessorKey: 'no',
			header: 'No',
			size: 20,
			cell: ({ row }: { row: { index: number } }) => (
				<div className='w-[10px]'>{row.index + 1}</div>
			),
		},
		{
			accessorKey: 'tgl_distribusi',
			header: 'TGL.DISTRIBUSI',
		},
		{
			accessorKey: 'no_faktur',
			header: 'NOMOR FAKTUR',
		},
		{
			accessorKey: 'supplier',
			header: 'STS.PENGELUARAN',
		},
		{
			accessorKey: 'unit_penerima',
			header: 'UNIT PENERIMA',
		},
		{
			accessorKey: 'grand_total',
			header: 'GRAND TOTAL',
		},
		{
			accessorKey: 'item_barang',
			header: 'ITEM BARANG',
		},
		{
			accessorKey: 'action',
			header: '#',
			cell: ({ row }: any) => {
				return (
					<div className='flex space-x-2'>
						<Tooltip text='Lihat'>
							<Button
								type='button'
								size='lg'
								icon='heroPencil'
								variant='solid'
								color='emerald'
								className='h-[34px] w-[34px] text-sm text-white transition-colors duration-300'
								onClick={() => {}}
							/>
						</Tooltip>
						<Tooltip text='Lihat'>
							<Button
								type='button'
								size='lg'
								icon='heroEye'
								variant='solid'
								color='blue'
								className='h-[34px] w-[34px] text-sm text-white transition-colors duration-300'
								onClick={() => {}}
							/>
						</Tooltip>
					</div>
				);
			},
		},
	];

	return { columns };
}
