/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/require-await */
import { useState } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';

import { toast } from 'react-toastify';

import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import Button from '../components/ui/Button';
import { useAuth } from '../context/authContext';
import Input from '../components/form/Input';
import LogoTemplate from '../templates/layouts/Logo/Logo.template';
import FieldWrap from '../components/form/FieldWrap';
import Icon from '../components/icon/Icon';
import Validation from '../components/form/Validation';

type TValues = {
	username: string;
	password: string;
};

const LoginPage = () => {
	const { onLogin, isLoading } = useAuth();
	const [passwordShowStatus, setPasswordShowStatus] = useState<boolean>(false);

	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
		},
		validate: (values: TValues) => {
			const errors: Partial<TValues> = {};

			if (!values.username) {
				errors.username = 'Required';
			}

			if (!values.password) {
				errors.password = 'Required';
			}

			return errors;
		},
		onSubmit: (values: TValues) => {
			onLogin(values.username, values.password)
				.then(() => {})
				.catch((e: any) => {
					toast.error(e?.response?.data?.message);
				});
		},
	});

	return (
		<PageWrapper
			isProtectedRoute={false}
			className='bg-[#d7e6fd] dark:bg-inherit'
			name='Sign In'>
			<div className='container mx-auto flex h-full items-center justify-center'>
				<div className=' w-auto rounded-lg border border-gray-200 bg-white p-6 shadow-lg md:w-3/12'>
					<div className='flex flex-col gap-8'>
						<div className='flex justify-center'>
							<LogoTemplate className=' h-44 w-44' />
						</div>
						<div className='text-center'>
							<span className='text-4xl font-semibold'>Login</span>
						</div>

						<form
							className='flex flex-col gap-4'
							onSubmit={formik.handleSubmit}
							noValidate>
							<div
								className={classNames({
									'mb-2': !formik.isValid,
								})}>
								<Validation
									isValid={formik.isValid}
									isTouched={formik.touched.username}
									invalidFeedback={formik.errors.username}
									validFeedback='Good'>
									<FieldWrap
										firstSuffix={<Icon icon='HeroEnvelope' className='mx-2' />}>
										<Input
											dimension='lg'
											id='username'
											autoComplete='username'
											name='username'
											placeholder='Email or username'
											value={formik.values.username}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
										/>
									</FieldWrap>
								</Validation>
							</div>
							<div
								className={classNames({
									'mb-2': !formik.isValid,
								})}>
								<Validation
									isValid={formik.isValid}
									isTouched={formik.touched.password}
									invalidFeedback={formik.errors.password}
									validFeedback='Good'>
									<FieldWrap
										firstSuffix={<Icon icon='HeroKey' className='mx-2' />}
										lastSuffix={
											<Icon
												className='mx-2 cursor-pointer'
												icon={
													passwordShowStatus ? 'HeroEyeSlash' : 'HeroEye'
												}
												onClick={() => {
													setPasswordShowStatus(!passwordShowStatus);
												}}
											/>
										}>
										<Input
											dimension='lg'
											type={passwordShowStatus ? 'text' : 'password'}
											autoComplete='current-password'
											id='password'
											name='password'
											placeholder='Password'
											value={formik.values.password}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
										/>
									</FieldWrap>
								</Validation>
							</div>
							<div>
								<Button
									size='lg'
									variant='solid'
									color='blue'
									isLoading={isLoading}
									className='w-full font-semibold'>
									Masuk
								</Button>
							</div>
							{/* <div>
								<Link to={puskesmasOnlinePages.onlineLoginPage.to}>
									<Button
										size='lg'
										variant='solid'
										className='w-full font-semibold'>
										Antrian Online
									</Button>
								</Link>
							</div> */}
						</form>
						<div>
							<span className='flex items-center  justify-center gap-2 text-sm'>
								<span className='text-zinc-400 dark:text-zinc-600'>
									2024 | Dinas Kesehatan Kabupaten Bandung
								</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</PageWrapper>
	);
};

export default LoginPage;
