/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/no-extraneous-dependencies */
import { jwtDecode } from 'jwt-decode';

export interface JwtPayload {
	iss?: string;
	iat?: number;
	exp?: number;
	nbf?: number;
	jti?: string;
	sub?: string;
	prv?: string;
	username?: string;
	puskesmas_id?: number;
	id?: number;
	staticSecret?: string;
}

const decodeToken = (token: string): JwtPayload | null => {
	try {
		const decoded = jwtDecode<JwtPayload>(token);
		return decoded;
	} catch (error) {
		return null;
	}
};

export default decodeToken;
