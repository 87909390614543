import categoriesDb from '../mocks/db/categories.db';
import productsDb from '../mocks/db/products.db';
import usersDb from '../mocks/db/users.db';
import rolesDb from '../mocks/db/roles.db';
import projectsDb from '../mocks/db/projects.db';

export const examplePages = {
	examplesPage: {
		id: 'examplesPage',
		to: '/examples-page',
		text: 'Examples Page',
		icon: 'HeroBookOpen',
	},
	duotoneIconsPage: {
		id: 'duotoneIconsPage',
		to: '/duotone-icons',
		text: 'Duotone Icons',
		icon: 'HeroCubeTransparent',
	},
};

export const appPages = {
	// DASHBOARD
	dashboardPage: {
		id: 'dashboardPage',
		to: '/dasboard',
		text: 'Dashboard',
		icon: 'HeroRectangleGroup',
		subPages: {
			utamaPage: {
				id: 'utamaPage',
				to: '/dasboard/utama',
				text: 'Utama',
				icon: 'HeroChartBar',
			},
			lplpoPage: {
				id: 'lplpoPage',
				to: '/dasboard/lplpo',
				text: 'LPLPO',
				icon: 'HeroClipboardDocument',
			},
			rkoPage: {
				id: 'rkoPage',
				to: '/dasboard/rko',
				text: 'RKO',
				icon: 'HeroClipboardDocumentList',
			},
		},
	},

	// KETERSEDIAAN
	ketersediaanPage: {
		id: 'ketersediaanPage',
		to: '/ketersediaan',
		text: 'Ketersediaan',
		icon: 'HeroClipboardDocumentCheck',
		subPages: {
			obatPage: {
				id: 'obatPage',
				to: '/ketersediaan/obat',
				text: 'Obat',
				icon: 'HeroHeart',
			},
			vaksinPage: {
				id: 'vaksinPage',
				to: '/ketersediaan/vaksin',
				text: 'Vaksin',
				icon: 'HeroBeaker',
			},
		},
	},

	// UPADATE BERITA
	updateBeritaPage: {
		id: 'updateBeritaPage',
		to: '/update-berita',
		text: 'Update Berita',
		icon: 'HeroNewspaper',
	},

	// E-LOGISTIK KEMKES
	eLogistikKemkesPage: {
		id: 'eLogistikKemkesPage',
		to: '/e-logistik-kemkes',
		text: 'E-Logistik Kemkes',
		icon: 'heroInbox',
		subPages: {
			indikatorPage: {
				id: 'indikatorPage',
				to: '/e-logistik-kemkes/indikator',
				text: 'Indikator',
				icon: 'HeroClipboardDocumentCheck',
				subPages: {
					kirimIndikatorPage: {
						id: 'kirimIndikatorPage',
						to: '/e-logistik-kemkes/indikator/kirim-indikator',
						text: 'Kirim Indikator',
						icon: 'HeroClipboardDocumentCheck',
					},
					lihatIndikatorPage: {
						id: 'lihatIndikatorPage',
						to: '/e-logistik-kemkes/indikator/lihat-indikator',
						text: 'Lihat Indikator',
						icon: 'HeroClipboardDocumentCheck',
					},
				},
			},
			ketersediaanKemkesPage: {
				id: 'KetersediaanKemkes',
				to: '/e-logistik-kemkes/ketersediaan',
				text: 'Ketersediaan',
				icon: 'HeroClipboardDocumentCheck',
				subPages: {
					obatKemkesPage: {
						id: 'obatKemkesPage',
						to: '/e-logistik-kemkes/ketersediaan/obat',
						text: 'Obat',
						icon: 'HeroClipboardDocumentCheck',
					},
					vaksinKemkesPage: {
						id: 'vaksinKemkesPage',
						to: '/e-logistik-kemkes/ketersediaan/vaksin',
						text: 'Vaksin',
						icon: 'HeroClipboardDocumentCheck',
					},
				},
			},
		},
	},

	// INFO KETERSEDIAAN
	infoKetersediaanPage: {
		id: 'inforKetersediaanPage',
		to: '/info-ketersediaan',
		text: 'Info Ketersediaan',
		icon: 'HeroRectangleGroup',
	},

	// Gudang Besar
	gudangBesarPage: {
		id: 'gudangBesarPage',
		to: '/gudang-besar',
		text: 'Gudang Besar',
		icon: 'heroArchiveBox',
		subPages: {
			stokBarangPage: {
				id: 'stokBarangPage',
				to: '/gudang-besar/stok-barang',
				text: 'Stok Barang',
				icon: 'HeroClipboardDocumentCheck',
			},
			penerimaanBarangPage: {
				id: 'penerimaanBarangPage',
				to: '/gudang-besar/penerimaan-barang',
				text: 'Penerimaan Barang',
				icon: 'HeroArrowDownTray',
			},
			pengeluaranBarangPage: {
				id: 'pengeluaranBarangPage',
				to: '/gudang-besar/pengeluaran-barang',
				text: 'Pengeluaran Barang',
				icon: 'HeroArrowUpTray',
			},
			returPkmPage: {
				id: 'returPkmPage',
				to: '/gudang-besar/retur-hibah-pkm',
				text: 'Retur/Hibah Pkm',
				icon: 'HeroArrowPath',
			},
			stokOpnamePage: {
				id: 'stokOpnamePage',
				to: '/gudang-besar/stok-opname',
				text: 'Stok Opname',
				icon: 'HeroClipboardDocumentCheck',
			},
		},
	},
	// Gudang Besar
	gudangVaksinPage: {
		id: 'gudangVaksinPage',
		to: '/gudang-vaksin',
		text: 'Gudang Vaksin',
		icon: 'heroArchiveBox',
		subPages: {
			stokBarangVaksinPage: {
				id: 'stokBarangVaksinPage',
				to: '/gudang-vaksin/stok-Barang',
				text: 'Stok Barang',
				icon: 'HeroClipboardDocumentCheck',
			},
			penerimaanBarangVaksinPage: {
				id: 'penerimaanBarangVaksinPage',
				to: '/gudang-vaksin/penerimaan-barang',
				text: 'Penerimaan Barang',
				icon: 'HeroClipboardDocumentCheck',
			},
			pengeluaranBarangVaksinPage: {
				id: 'pengeluaranBarangVaksinPage',
				to: '/gudang-vaksin/pengeluaran-barang',
				text: 'Pengeluaran Barang',
				icon: 'HeroClipboardDocumentCheck',
			},
			katuStokVaksinPage: {
				id: 'kartuStokVaksinPage',
				to: '/gudang-vaksin/kartu-stok',
				text: 'Kartu Stok',
				icon: 'HeroClipboardDocumentCheck',
			},
			stokOpnameVaksinPage: {
				id: 'stokOpnameVaksinPage',
				to: '/gudang-vaksin/stok-opname',
				text: 'Stok Opname',
				icon: 'HeroClipboardDocumentCheck',
			},
			lapKetersediaanPage: {
				id: 'lapKetersediaanPage',
				to: '/gudang-vaksin/laporan-ketersediaan',
				text: 'Lap.Ketersediaan',
				icon: 'HeroClipboardDocumentCheck',
			},
			lapDistribusiPage: {
				id: 'lapDistribusiPage',
				to: '/gudang-vaksin/laporan-distribusi',
				text: 'Lap.Distribusi',
				icon: 'HeroClipboardDocumentCheck',
			},
			lapDistribusiUnitPage: {
				id: 'lapDistribusiUnitPage',
				to: '/gudang-vaksin/laporan-distribusi-unit',
				text: 'Lap.Distribusi Unit',
				icon: 'HeroClipboardDocumentCheck',
			},
			lapSoPage: {
				id: 'lapDistribusiPage',
				to: '/gudang-vaksin/laporan-so',
				text: 'Lap.SO (Triwulan)',
				icon: 'HeroClipboardDocumentCheck',
			},
		},
	},
	// Gudang Karantina
	gudangKarantinaPage: {
		id: 'gudangKarantinaPage',
		to: '/gudang-karantina',
		text: 'Gudang Karantina',
		icon: 'heroArchiveBox',
		subPages: {
			dataKarantinaPage: {
				id: 'dataKatantinaPage',
				to: '/gudang-karantina/data-karantina',
				text: 'Data Karantina',
				icon: 'HeroClipboardDocumentCheck',
			},
			dataPemusnahanPage: {
				id: 'dataPemusnahanPage',
				to: '/gudang-besar/data-pemusnahan',
				text: 'Penerimaan Barang',
				icon: 'HeroClipboardDocumentCheck',
			},
		},
	},

	// Laporan Gudang
	lapGudangPage: {
		id: 'lapGudangPage',
		to: '/laporan-gudang',
		text: 'Lap.Gudang',
		icon: 'heroArchiveBox',
		subPages: {
			kartuStokbBatchPage: {
				id: 'kartuStokBatchPage',
				to: '/laporan-gudang/kartu-stok-batch',
				text: 'Kartu Stok (Batch)',
				icon: 'HeroClipboardDocumentCheck',
			},
			kartuStokbGroupPage: {
				id: 'kartuStokGroupPage',
				to: '/laporan-gudang/kartu-stok-group',
				text: 'Kartu Stok (Group)',
				icon: 'HeroClipboardDocumentCheck',
			},
			ketersediaanGudangPage: {
				id: 'ketersediaanGudangPage',
				to: '/laporan-gudang/ketersediaan',
				text: 'Ketersediaan',
				icon: 'HeroClipboardDocumentCheck',
			},
			mutasiSediaanPage: {
				id: 'mutasiSediaanPage',
				to: '/laporan-gudang/mutasi-sediaan',
				text: 'Mutasi Sediaan',
				icon: 'HeroClipboardDocumentCheck',
			},
			distribusiHarianPage: {
				id: 'distribusiHarianPage',
				to: '/laporan-gudang/distribusi-harian',
				text: 'Distribusi Harian',
				icon: 'HeroClipboardDocumentCheck',
			},
			distribusiUnitDetailPage: {
				id: 'distribusiUnitDetailPage',
				to: '/laporan-gudang/distribusi-unit-detail',
				text: 'Distribusi Unit Detail',
				icon: 'HeroClipboardDocumentCheck',
			},
			distribusiKeunganPage: {
				id: 'distribusiKeuanganPage',
				to: '/laporan-gudang/distribusi-keuangan',
				text: 'Distribusi (Keuangan)',
				icon: 'HeroClipboardDocumentCheck',
			},
			psikotropikaPage: {
				id: 'psikotropikaPage',
				to: '/laporan-gudang/psikotropika',
				text: 'Psikotropika',
				icon: 'HeroClipboardDocumentCheck',
			},
			rkoPuskesmasPage: {
				id: 'rkoPuskesmasPage',
				to: '/laporan-gudang/rko-puskesmas',
				text: 'RKO Puskemas',
				icon: 'HeroClipboardDocumentCheck',
			},
			lplpoPuskesmasPage: {
				id: 'lplpoPuskesmaPage',
				to: '/laporan-gudang/lplpo-puskesmas',
				text: 'LPLPO Puskesmas',
				icon: 'HeroClipboardDocumentCheck',
			},
		},
	},
	// Master
	masterPage: {
		id: 'masterPage',
		to: '/master',
		text: 'Master',
		icon: 'HeroCog8Tooth',
		subPages: {
			dataFornasPage: {
				id: 'dataFornasPage',
				to: '/master/data-fornas',
				text: 'Data Fornas',
				icon: 'HeroCog8Tooth',
			},
			dataJknPage: {
				id: 'dataJknPage',
				to: '/master/data-jkn',
				text: 'Data JKN',
				icon: 'HeroCog8Tooth',
			},
			dataLplpoPage: {
				id: 'dataLplpoPage',
				to: '/master/data-lplpo',
				text: 'Data LPLPO',
				icon: 'HeroCog8Tooth',
			},
			dataObatIndikatorPage: {
				id: 'dataObatIndikatorPage',
				to: '/master/data-obat-indikator',
				text: 'Data Obat Indikator',
				icon: 'HeroCog8Tooth',
			},
			dataProgramPage: {
				id: 'dataProgramPage',
				to: '/master/data-program',
				text: 'Data Program',
				icon: 'HeroCog8Tooth',
			},
			dataVaksinPage: {
				id: 'dataVaksinPage',
				to: '/master/data-vaksin',
				text: 'Data Vaksin',
				icon: 'HeroCog8Tooth',
			},
			dataPegawaiPage: {
				id: 'dataPegawaiPage',
				to: '/master/data-pegawai',
				text: 'Data Pegawai',
				icon: 'HeroCog8Tooth',
			},
			dataPuskesmasPage: {
				id: 'dataPuskesmasPage',
				to: '/master/data-puskesmas',
				text: 'Data Puskesmas',
				icon: 'HeroCog8Tooth',
			},
			dataPemberiPage: {
				id: 'dataPemberiPage',
				to: '/master/data-pemberi',
				text: 'Data Pemberi (SBBK)',
				icon: 'HeroCog8Tooth',
			},
			dataPenerimaPage: {
				id: 'dataPenerimaPage',
				to: '/master/data-penerima',
				text: 'Data Penerima (SBBK)',
				icon: 'HeroCog8Tooth',
			},
			dataSupplierPage: {
				id: 'dataSupplierPage',
				to: '/master/data-supplier',
				text: 'Data Supplier',
				icon: 'HeroCog8Tooth',
			},
			dataRumahSakitPage: {
				id: 'dataRumahSakitPage',
				to: '/master/data-rumah-sakit',
				text: 'Data Rumah Sakit',
				icon: 'HeroCog8Tooth',
			},
		},
	},
};

export const daftarOnlinePages = {
	id: 'daftarOnline',
	to: '/daftar-online',
	text: 'Daftar Online',
	icon: 'HeroCog8Tooth',
};

export const listBPJSPages = {
	id: 'listBPJS',
	to: '/list-bpjs',
	text: 'List BPJS',
	icon: 'HeroCog8Tooth',
};

export const listPoliPage = {
	id: 'listPoli',
	to: '/list-poli',
	text: 'List Poli',
	icon: 'HeroCog8Tooth',
};

export const listETicketsOnlinePage = {
	id: 'etickets',
	to: '/e-tickets',
	text: 'E Tickets',
	icon: 'HeroCog8Tooth',
};

export const componentsPages = {
	uiPages: {
		id: 'uiPages',
		to: '/ui',
		text: 'UI',
		icon: 'HeroPuzzlePiece',
		subPages: {
			alertPage: {
				id: 'alertPage',
				to: '/ui/alert',
				text: 'Alert',
				icon: 'HeroBell',
			},
			badgePage: {
				id: 'badgePage',
				to: '/ui/badge',
				text: 'Badge',
				icon: 'HeroSparkles',
			},
			buttonPage: {
				id: 'buttonPage',
				to: '/ui/button',
				text: 'Button',
				icon: 'HeroRectangleStack',
			},
			buttonGroupPage: {
				id: 'buttonGroupPage',
				to: '/ui/button-group',
				text: 'Button Group',
				icon: 'HeroRectangleStack',
			},
			cardPage: {
				id: 'cardPage',
				to: '/ui/card',
				text: 'Card',
				icon: 'HeroSquare2Stack',
			},
			collapsePage: {
				id: 'collapsePage',
				to: '/ui/collapse',
				text: 'Collapse',
				icon: 'HeroBarsArrowDown',
			},
			dropdownPage: {
				id: 'dropdownPage',
				to: '/ui/dropdown',
				text: 'Dropdown',
				icon: 'HeroQueueList',
			},
			modalPage: {
				id: 'modalPage',
				to: '/ui/modal',
				text: 'Modal',
				icon: 'HeroChatBubbleBottomCenter',
			},
			offcanvasPage: {
				id: 'offcanvasPage',
				to: '/ui/offcanvas',
				text: 'Offcanvas',
				icon: 'HeroBars3BottomRight',
			},
			progressPage: {
				id: 'progressPage',
				to: '/ui/progress',
				text: 'Progress',
				icon: 'HeroChartBar',
			},
			tablePage: {
				id: 'tablePage',
				to: '/ui/table',
				text: 'Table',
				icon: 'HeroTableCells',
			},
			tooltipPage: {
				id: 'tooltipPage',
				to: '/ui/tooltip',
				text: 'Tooltip',
				icon: 'HeroChatBubbleLeftEllipsis',
			},
		},
	},
	formPages: {
		id: 'formPages',
		to: '/form',
		text: 'Form',
		icon: 'HeroPencilSquare',
		subPages: {
			fieldWrapPage: {
				id: 'fieldWrapPage',
				to: '/form/field-wrap',
				text: 'Field Wrap',
				icon: 'HeroInbox',
			},
			checkboxPage: {
				id: 'checkboxPage',
				to: '/form/checkbox',
				text: 'Checkbox',
				icon: 'HeroStop',
			},
			checkboxGroupPage: {
				id: 'checkboxGroupPage',
				to: '/form/checkbox-group',
				text: 'Checkbox Group',
				icon: 'HeroListBullet',
			},
			inputPage: {
				id: 'inputPage',
				to: '/form/input',
				text: 'Input',
				icon: 'HeroRectangleStack',
			},
			labelPage: {
				id: 'labelPage',
				to: '/form/label',
				text: 'Label',
				icon: 'HeroPencil',
			},
			radioPage: {
				id: 'radioPage',
				to: '/form/radio',
				text: 'Radio',
				icon: 'HeroStopCircle',
			},
			richTextPage: {
				id: 'richTextPage',
				to: '/form/rich-text',
				text: 'Rich Text',
				icon: 'HeroBars3CenterLeft',
			},
			selectPage: {
				id: 'selectPage',
				to: '/form/select',
				text: 'Select',
				icon: 'HeroQueueList',
			},
			selectReactPage: {
				id: 'selectReactPage',
				to: '/form/select-react',
				text: 'Select React',
				icon: 'HeroQueueList',
			},
			textareaPage: {
				id: 'textareaPage',
				to: '/form/textarea',
				text: 'Textarea',
				icon: 'HeroBars3BottomLeft',
			},
			validationPage: {
				id: 'validationPage',
				to: '/form/validation',
				text: 'Validation',
				icon: 'HeroShieldCheck',
			},
		},
	},
	integratedPages: {
		id: 'integratedPages',
		to: '/integrated',
		text: 'Integrated',
		icon: 'HeroBuildingLibrary',
		subPages: {
			reactDateRangePage: {
				id: 'reactDateRangePage',
				to: '/integrated/react-date-range',
				text: 'React Date Range',
				icon: 'HeroCalendarDays',
			},
			fullCalendarPage: {
				id: 'fullCalendarPage',
				to: '/integrated/full-calendar',
				text: 'Full Calendar',
				icon: 'HeroCalendar',
			},
			apexChartsPage: {
				id: 'apexChartsPage',
				to: '/integrated/apex-charts',
				text: 'ApexCharts',
				icon: 'HeroChartBar',
			},
			reactSimpleMapsPage: {
				id: 'reactSimpleMapsPage',
				to: '/integrated/react-simple-maps',
				text: 'React Simple Maps',
				icon: 'HeroMap',
			},
			waveSurferPage: {
				id: 'waveSurferPage',
				to: '/integrated/wave-surfer',
				text: 'WaveSurfer',
				icon: 'HeroMusicalNote',
			},
			richTextPage: {
				id: 'richTextPage',
				to: '/integrated/slate-react',
				text: 'Rich Text',
				icon: 'HeroBars3BottomLeft',
			},
			reactSelectPage: {
				id: 'reactSelectPage',
				to: '/integrated/react-select',
				text: 'React Select',
				icon: 'HeroQueueList',
			},
		},
	},
	iconsPage: {
		id: 'iconsPage',
		to: '/icons',
		text: 'Icons',
		icon: 'HeroBuildingLibrary',
		subPages: {
			heroiconsPage: {
				id: 'heroiconsPage',
				to: '/icons/heroicons',
				text: 'Heroicons',
				icon: 'HeroShieldCheck',
			},
			duotoneIconsPage: {
				id: 'duotoneIconsPage',
				to: '/icons/duotone-icons',
				text: 'Duotone Icons',
				icon: 'DuoPicker',
			},
		},
	},
};

export const authPages = {
	loginPage: {
		id: 'loginPage',
		to: '/login',
		text: 'Login',
		icon: 'HeroArrowRightOnRectangle',
	},
	profilePage: {
		id: 'profilePage',
		to: '/profile',
		text: 'Profile',
		icon: 'HeroUser',
	},
};

// ONLINE PUSKESMAS
export const puskesmasOnlinePages = {
	onlineLoginPage: {
		id: 'onlineLoginPage',
		to: '/online-login',
		text: 'Online Login',
		icon: 'HeroArrowRightOnRectangle',
	},
	listCategoryPuskesmasOnline: {
		id: 'listCategoryPuskesmasOnline',
		to: '/puskesmas-online/category',
		text: 'List Category Puskesmas Online',
		icon: 'HeroArrowRightOnRectangle',
	},
	antrianPassienOnlinePage: {
		id: 'antrianPassienPage',
		to: '/puskesmas-online/antrian-passien',
		text: 'Antrian Passien',
		icon: 'HeroArrowRightOnRectangle',
	},
	listAntrianPendaftaranPage: {
		id: 'listAntrianPendaftaranPage',
		to: '/puskesmas-online/antrian-pendaftaran',
		text: 'List Antrian Pendaftaran',
		icon: 'HeroArrowRightOnRectangle',
	},
};

export const antrianPages = {
	antrianPassienPage: {
		id: 'antrianPassienPage',
		to: '/antrian-passien',
		text: 'Antrian Passien',
		icon: 'HeroArrowRightOnRectangle',
	},
};

const pagesConfig = {
	...examplePages,
	...authPages,
	...puskesmasOnlinePages,
};

export default pagesConfig;
