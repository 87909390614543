import React from 'react';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Container from '../../../components/layouts/Container/Container';
import Subheader, { SubheaderLeft } from '../../../components/layouts/Subheader/Subheader';
import Card, { CardHeader, CardBody, CardFooter } from '../../../components/ui/Card';
import GTable from '../../../components/ui/GTable';
import Input from '../../../components/form/Input';
import SelectReact from '../../../components/form/SelectReact';
import Tooltip from '../../../components/ui/Tooltip';
import Button from '../../../components/ui/Button';
import useTableReturBesar from './hooks/useTableReturBesar';

function ReturPkmBesar() {
	const { columns } = useTableReturBesar();

	const staticData = [
		{
			tgl: '2023-05-01',
			no_faktur: 'FTK101',
			supplier: 'Pengeluaran',
			status: 'Diterima',
			penerima: 'Gudang A',
			keterangan: 'Barang diterima dalam kondisi baik',
		},
		{
			tgl: '2023-05-10',
			no_faktur: 'FTK102',
			supplier: 'Pengeluaran',
			status: 'Diterima',
			penerima: 'Gudang B',
			keterangan: 'Barang diterima dengan kerusakan ringan',
		},
		{
			tgl: '2023-05-15',
			no_faktur: 'FTK103',
			supplier: 'Pengeluaran',
			status: 'Dikembalikan',
			penerima: 'Gudang C',
			keterangan: 'Barang dikembalikan karena kesalahan pengiriman',
		},
	];

	const ListKonseling = [
		{ label: 'Tersedia', value: 'tersedia' },
		{ label: 'Kosong', value: 'kosong' },
		{ label: 'Segera Ada', value: 'segera ada' },
	];
	return (
		<PageWrapper>
			<Subheader>
				<SubheaderLeft>
					<h3 className='font-bold text-blue-600'>RETUR PUSKESMAS</h3>
				</SubheaderLeft>
			</Subheader>
			<Container>
				<Card>
					<CardBody>
						<div className='grid grid-cols-12 items-center gap-4'>
							<div className='col-span-2'>
								<SelectReact
									dimension='lg'
									options={ListKonseling}
									id='ketersediaan'
									name='ketersediaan'
									className='w-full'
								/>
							</div>

							<Input
								type='text'
								dimension='lg'
								id='name'
								name='name'
								placeholder='Ketik Nama/Kode Barang/Batch/Program'
								className='col-span-6 w-full'
							/>
							<div className='flex justify-start gap-2'>
								<Tooltip text='Cari' placement='top'>
									<Button
										type='button'
										icon='HeroMagnifyingGlass'
										size='lg'
										variant='solid'
										color='emerald'
									/>
								</Tooltip>

								<Tooltip text='Reset' placement='top'>
									<Button
										type='button'
										icon='HeroArrowPath'
										size='lg'
										variant='solid'
										color='blue'
									/>
								</Tooltip>
							</div>
						</div>
					</CardBody>
				</Card>
				<Card className='mt-4'>
					<CardBody>
						<GTable data={staticData} columns={columns} />
					</CardBody>
				</Card>
			</Container>
		</PageWrapper>
	);
}

export default ReturPkmBesar;
