import React from 'react';
import Icon from '../../../components/icon/Icon';
import Badge from '../../../components/ui/Badge';
import { NavButton, NavItem, NavSeparator } from '../../../components/layouts/Navigation/Nav';
import { appPages, authPages } from '../../../config/pages.config';
import User from '../../../components/layouts/User/User';
import { useAuth } from '../../../context/authContext';
import decodeToken from '../../../utils/decodeToken';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useAxiosFunction from '../../../hooks/useAxiosFunction';

const UserTemplate = () => {
	const { isLoading, userData, onLogout } = useAuth();
	const [tokenStorage] = useLocalStorage('token', null);
	const dataUser = decodeToken(tokenStorage as string);
	const { axiosFetch } = useAxiosFunction();

	const handleLogout = async () => {
		const res = await axiosFetch('/api/v1/logout', {
			method: 'POST',
		});

		if (res?.status === 200) {
			onLogout();
		}
	};

	return (
		<User
			isLoading={isLoading}
			name={dataUser?.username as string}
			nameSuffix={userData?.isVerified && <Icon icon='HeroCheckBadge' color='blue' />}
			position={userData?.position}
			src={userData?.image?.thumb}
			suffix={
				<Badge color='amber' variant='solid' className='text-xs font-bold'>
					PRO
				</Badge>
			}>
			<NavSeparator />
			{/* <NavItem {...authPages.profilePage} /> */}
			{/* <NavItem {...appPages.mailAppPages.subPages.inboxPages}>
				<Badge variant='solid' className='leading-none'>
					3
				</Badge>
				<NavButton icon='HeroPlusCircle' title='New Mail' onClick={() => {}} />
			</NavItem> */}
			<NavItem text='Logout' icon='HeroArrowRightOnRectangle' onClick={handleLogout} />
		</User>
	);
};

export default UserTemplate;
