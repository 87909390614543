/* eslint-disable no-useless-catch */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../context/authContext';
import { getToken } from '../components/helper/util';

interface AxiosError {
	message: string;
	response?: {
		status: number;
		data: any;
	};
}

const useAxiosFunction = () => {
	const [response, setResponse] = useState<any>([]);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [controller, setController] = useState();
	const { onLogout } = useAuth();
	const [statusCode, setStatusCode] = useState<number>();

	const axiosFetch = async (path: string, option: any) => {
		setLoading(true);
		try {
			const res = await customFetch(path, option);

			setResponse(res.data);
			setStatusCode(res.status);
			setLoading(false);
			return res;
		} catch (err) {
			// @ts-ignore
			if (err.response) {
				// @ts-ignore
				const { status, data } = err.response;
				if (status === 400) {
					toast.error(data.message);
				} else if (status === 401) {
					onLogout();
				} else if (status === 404) {
					toast.error('Not Found');
				} else if (status === 500) {
					toast.error('Internal Server Error');
				} else {
					toast.error('An error occurred');
				}
				setStatusCode(status);
			} else {
				// @ts-ignore
				toast.error(err.message);
			}
			// @ts-ignore
			setError(err.message);
			setLoading(false);
		}
	};

	useEffect(() => {
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		return () => controller && controller.abort();
	}, [controller]);

	return { response, error, loading, axiosFetch, statusCode };
};

export default useAxiosFunction;

export const BASE_URL = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({
	baseURL: BASE_URL,
	headers: { 'Content-Type': 'application/json' },
});

axiosInstance.interceptors.request.use(
	(config) => {
		let token = getToken();
		if (token) {
			token = token.replace(/"/g, '');
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

// Define a function to perform the request
export const customFetch = async (path: string, options: any) => {
	try {
		const response = await axiosInstance.request({
			method: options.method || 'GET',
			url: path,
			data: options.data,
			params: options.params,
		});

		return response;
	} catch (error) {
		throw error;
	}
};
