import React, { useState } from 'react';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Container from '../../../components/layouts/Container/Container';
import Subheader, { SubheaderLeft } from '../../../components/layouts/Subheader/Subheader';
import Card, { CardHeader, CardBody, CardFooter } from '../../../components/ui/Card';
import GTable from '../../../components/ui/GTable';
import Input from '../../../components/form/Input';
import SelectReact from '../../../components/form/SelectReact';
import Tooltip from '../../../components/ui/Tooltip';
import Button from '../../../components/ui/Button';

function LapDistribusiUnit() {
	const staticData = [
		{
			bulan: 'Januari',
			tahun: 2023,
			keterangan: 'Peningkatan stok untuk awal tahun',
		},
		{
			bulan: 'Februari',
			tahun: 2023,
			keterangan: 'Pengeluaran besar untuk kebutuhan rumah sakit',
		},
		{
			bulan: 'Maret',
			tahun: 2023,
			keterangan: 'Stok stabil, sedikit pengeluaran',
		},
		{
			bulan: 'April',
			tahun: 2023,
			keterangan: 'Penerimaan barang baru dari supplier utama',
		},
		{
			bulan: 'Mei',
			tahun: 2023,
			keterangan: 'Penyesuaian stok dan inventaris',
		},
	];

	const ListKonseling = [
		{ label: 'Tersedia', value: 'tersedia' },
		{ label: 'Kosong', value: 'kosong' },
		{ label: 'Segera Ada', value: 'segera ada' },
	];

	const yearOptions = Array.from({ length: 2024 - 2017 + 1 }, (_, index) => ({
		value: (2017 + index).toString(),
		label: (2017 + index).toString(),
	}));

	// Mendapatkan bulan dan tahun saat ini
	const currentYear = new Date().getFullYear().toString();
	const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');

	const [selectedYear, setSelectedYear] = useState(currentYear);
	const [selectedMonth, setSelectedMonth] = useState(currentMonth);

	const handleYearChange = (selectedOption: any) => {
		setSelectedYear(selectedOption.value);
	};

	const monthOptions = [
		{ value: '01', label: 'Januari' },
		{ value: '02', label: 'Februari' },
		{ value: '03', label: 'Maret' },
		{ value: '04', label: 'April' },
		{ value: '05', label: 'Mei' },
		{ value: '06', label: 'Juni' },
		{ value: '07', label: 'Juli' },
		{ value: '08', label: 'Agustus' },
		{ value: '09', label: 'September' },
		{ value: '10', label: 'Oktober' },
		{ value: '11', label: 'November' },
		{ value: '12', label: 'Desember' },
	];

	const handleMonthChange = (selectedOption: any) => {
		setSelectedMonth(selectedOption.value);
	};

	return (
		<PageWrapper>
			<Subheader>
				<SubheaderLeft>
					<h3 className='font-bold text-blue-600'>DISTRIBUSI VAKSIN UNIT DETAIL</h3>
				</SubheaderLeft>
			</Subheader>
			<Container>
				<Card>
					<CardBody>
						<div className='grid grid-cols-12 items-center gap-4'>
							<div className='col-span-2'>
								<SelectReact
									dimension='lg'
									options={monthOptions}
									id='month'
									name='month'
									onChange={handleMonthChange}
									value={monthOptions.find(
										(month) => month.value === selectedMonth,
									)}
								/>
							</div>
							<div className='col-span-2'>
								<SelectReact
									dimension='lg'
									options={monthOptions}
									id='month'
									name='month'
									onChange={handleMonthChange}
									value={monthOptions.find(
										(month) => month.value === selectedMonth,
									)}
								/>
							</div>

							<div className='col-span-2'>
								<SelectReact
									dimension='lg'
									options={yearOptions}
									id='year'
									name='year'
									onChange={handleYearChange}
									value={yearOptions.find((year) => year.value === selectedYear)}
								/>
							</div>

							<div className='col-span-2'>
								<SelectReact
									dimension='lg'
									options={ListKonseling}
									id='ketersediaan'
									name='ketersediaan'
									className='w-full'
								/>
							</div>

							<Input
								type='text'
								dimension='lg'
								id='name'
								name='name'
								placeholder='Ketik Nama Barang'
								className='col-span-6 w-full'
							/>

							<div className='col-span-4 flex justify-start gap-2'>
								<Tooltip text='Cari' placement='top'>
									<Button
										type='button'
										icon='HeroMagnifyingGlass'
										size='lg'
										variant='solid'
										color='emerald'
									/>
								</Tooltip>

								<Tooltip text='Reset' placement='top'>
									<Button
										type='button'
										icon='HeroArrowPath'
										size='lg'
										variant='solid'
										color='blue'
									/>
								</Tooltip>

								<Tooltip text='Export Excel' placement='top'>
									<Button
										type='button'
										icon='HeroDocument'
										size='lg'
										variant='solid'
										color='lime'
									/>
								</Tooltip>

								<Tooltip text='Print' placement='top'>
									<Button
										type='button'
										icon='HeroPrinter'
										size='lg'
										variant='solid'
										color='sky'
									/>
								</Tooltip>
							</div>
						</div>
					</CardBody>
				</Card>
				{/* <Card className='mt-4'>
					<CardBody>
						<GTable data={staticData} columns={columns} />
					</CardBody>
				</Card> */}
			</Container>
		</PageWrapper>
	);
}

export default LapDistribusiUnit;
