import {useAuth} from "../../context/authContext";


export const formatToRupiah = (amount: number) => {
    return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0
    }).format(amount);
};


export const getToken = () => {
    // Ideally, use React Context or a similar mechanism to get the token
    // This example assumes you are able to access the token globally
    return localStorage.getItem('token');
    // return tokenStorage;
};

