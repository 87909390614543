/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, HTMLAttributes } from 'react';

import { IconKabBandung } from '../../../assets/images';

type ILogoTemplateProps = HTMLAttributes<HTMLOrSVGElement>;
const LogoTemplate: FC<ILogoTemplateProps> = (props) => {
	const { ...rest } = props;
	return (
		<img
			src={IconKabBandung}
			className={`${props.className || ' h-20 w-20'} rounded-lg`}
			alt=''
		/>
	);
};

export default LogoTemplate;
